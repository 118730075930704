<!-- media 정산보고서 -->
<template>
  <v-container
          id='reportAds'
          fluid
          tag='section'>
    <v-row
            align='center'
            justify='center'>
      <v-col cols='12'>
        <base-material-card
                color='green'>
          <template v-slot:heading>
            <h1 class='display-2 font-weight-regular'>
              [ {{ datePicker.start.date }} - {{ datePicker.end.date }} ] 정산 보고서
            </h1>
          </template>
          <v-card-text>
            <v-row justify="space-between" align="center">
              <h2 style="padding: 20px 10px;">
                매출 현황 및 추이
              </h2>
              <v-row justify="end" align="center">
                <v-col cols="2">
                  <v-select
                    :items="config.inventoryOptions"
                    v-model="vModel.inventory"
                    label="인벤토리를 선택하세요."
                    color="secondary"
                    @change="inventoryChange()" />
                </v-col>
                <v-col cols="2">
                  <v-menu
                          ref="menu1"
                          v-model="datePicker.start.menu"
                          :close-on-content-click="false"
                          :return-value.sync="datePicker.start.date"
                          transition="scale-transition"
                          min-width="290px"
                          offset-y >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                              v-model="datePicker.start.date"
                              color="secondary"
                              label="Start date"
                              prepend-icon="mdi-calendar-outline"
                              readonly
                              v-on="on" />
                    </template>

                    <v-date-picker
                            v-model="datePicker.start.date"
                            color="secondary"
                            landscape
                            scrollable >
                      <v-spacer />
                      <v-btn
                              color="secondary"
                              @click="datePicker.start.menu = false" >
                        Cancel
                      </v-btn>

                      <v-btn
                              color="secondary"
                              @click="$refs.menu1.save(datePicker.start.date)" >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col>
                <span>-</span>
                <v-col cols="2">
                  <v-menu
                          ref="menu2"
                          v-model="datePicker.end.menu"
                          :close-on-content-click="false"
                          :return-value.sync="datePicker.end.date"
                          transition="scale-transition"
                          min-width="290px"
                          offset-y >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                              v-model="datePicker.end.date"
                              color="secondary"
                              label="End date"
                              prepend-icon="mdi-calendar-outline"
                              readonly
                              v-on="on" />
                    </template>

                    <v-date-picker
                            v-model="datePicker.end.date"
                            color="secondary"
                            landscape
                            scrollable >
                      <v-spacer />
                      <v-btn
                              color="secondary"
                              @click="datePicker.end.menu = false" >
                        Cancel
                      </v-btn>

                      <v-btn
                              color="secondary"
                              @click="$refs.menu2.save(datePicker.end.date)" >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col>
                <v-btn
                        :disabled="!vModel.valid"
                        color="success"
                        default
                        @click="submitDate">
                  검색
                </v-btn>
              </v-row>
            </v-row>
            <template v-if="resultReport">
              <v-row>
                <v-col cols="2">
                  <v-card outlined class="pa-3 mt-0">
                    <v-card-text class="justify-center font-weight-regular text-center">
                      <h3>Active view</h3><br>
                      <h1>{{numReplace(resultReport.card.total_views)}}</h1>
                    </v-card-text>
                  </v-card>
                  <v-card outlined class="pa-3">
                    <v-card-text class="justify-center font-weight-regular text-center">
                      <h3>Active view당 단가</h3><br>
                      <h1>{{toFixed(resultReport.card.total_media_revenue, resultReport.card.total_views)}}원</h1>
                    </v-card-text>
                  </v-card>

                  <v-card outlined class="pa-3 mt-0">
                    <v-card-text class="justify-center font-weight-regular text-center">
                      <h3>Contents view</h3><br>
                      <h1>{{numReplace(resultReport.card.total_feed_content_viewable_impressions)}}</h1>
                    </v-card-text>
                  </v-card>

                  <v-card outlined class="pa-3">
                    <v-card-text class="justify-center font-weight-regular text-center">
                      <h3>광고 수익</h3><br>
                      <h1 class="d-inline-block">{{numReplace(resultReport.card.total_media_revenue)}}원</h1>
                      <span class="d-inline-block ml-1">(VAT제외)</span>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="10" ref="chart1">
                  <fusioncharts
                          :type='chartData.type'
                          :width='chartData.width'
                          :height='chartData.height'
                          :dataFormat='chartData.dataFormat'
                          :dataSource='chartData.dataSource'
                  ></fusioncharts>
                </v-col>
              </v-row>
              <v-divider class="mt-5 mb-5" />
              <v-row justify="space-between" align="center" class="ma-0">
                <h2 style="padding: 20px 10px;">
                  일별 정산 리포트
                </h2>
              </v-row>
              <v-col v-if="resultReport" cols="12" class="pa-0 ma-0">
                <v-data-table
                        :headers="config.headersReport"
                        :items="resultReport.list"
                        :items-per-page="resultReport.list.length"
                        item-key="key"
                        no-data-text="리스트가 없습니다."
                        hide-default-footer>
                  <template v-slot:item="{ item }">
                    <tr>
                      <td class="text-center">{{item.date}}</td>
                      <td class="text-center">{{ numReplace(item.views) }}</td>
                      <td class="text-center">{{ toFixed(item.media_revenue, item.views) }}원</td>
                      <td class="text-center">{{ numReplace(item.feed_content_viewable_impressions) }}</td>
                      <td class="text-center">{{ numReplace(item.media_revenue) }}원</td>
                    </tr>
                  </template>
                </v-data-table>
              </v-col>
            </template>
          </v-card-text>
        </base-material-card>
      </v-col>
    </v-row>
    <base-material-snackbar
            v-model="vModel.snackbar"
            :type="vModel.snackbarType"
            v-bind="{
          'top': true,
          'right': true
        }">
      <span style="display:block;width:300px;margin:0;">{{vModel.errorMessage}}</span>
    </base-material-snackbar>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'
import { common } from '../../mixins/common.js'
import { chart } from '../../mixins/chart.js'

export default {
  mixins: [ common, chart ],
  created () {
    this.refresh()
  },
  data () {
    return {
      response: {
        report: null,
      },
      vModel: {
        valid: false,
        snackbar: false,
        snackbarType: 'warning',
        errorMessage: null,
        inventory: null,
      },
      datePicker: {
        start: {
          date: '',
          menu: false
        },
        end: {
          date: '',
          menu: false
        }
      },
      config: {
        headersReport: [
          { text: '날짜', value: 'daily', align: 'center' },
          { text: 'Active view', value: 'views', align: 'center' },
          { text: 'Active view당 단가', value: 'counter', align: 'center' },
          { text: 'Contents view', value: 'feed_content_viewable_impressions', align: 'center' },
          { text: '광고 수익', value: 'content_revenue', align: 'center' },
        ],
        inventoryOptions: [],
      },
      chartData: {}
    }
  },
  methods: {
    ...mapActions(['xhttp']),
    snack (type, val) {
      this.vModel.errorMessage = val
      this.vModel.snackbarType = type
      this.vModel.snackbar = true
    },
    /* 날짜 검색 선택시 */
    submitDate (val) {
      let start = this.datePicker.start.date
      let end = this.datePicker.end.date
      let startNum = Number(start.replace(/-/gi, ''))
      let endNum = Number(end.replace(/-/gi, ''))
      if (startNum > endNum) {
        this.snack('warning', '시작일을 종료일보다 앞날짜로 선택하세요.')
      } else {
        this.inventoryChange()
      }
    },
    /* 페이지 로딩시 */
    refresh () {
      if (this.$route.query.start) {
        this.datePicker.start.date = this.$route.query.start
        this.datePicker.end.date = this.$route.query.end
      } else {
        this.datePicker.start.date = this.$moment(new Date()).add(-6, 'days').format('YYYY-MM-DD')
        this.datePicker.end.date = this.$moment(new Date()).format('YYYY-MM-DD')
      }

      if (this.$route.query.inventory) {
        this.vModel.inventory = Number(this.$route.query.inventory)
      }

      let send = {
        sdate: this.datePicker.start.date,
        edate: this.datePicker.end.date,
        inventory: this.vModel.inventory
      }
      if (!this.$route.query.inventory) {
        delete send.inventory
        this.vModel.inventory = ''
      }

      this.response.report = null

      this.chartData = []
      this.getReport(send)
      this.mediaInventoryList()
    },
    /* 차트 정보 가져오기 */
    getReport (send) {
      this.xhttp({
        url: '/reports/ads/daily/media',
        method: 'get',
        params: send
      }).then((response) => {
        if (response.status === 200) {
          this.response.report = response.data.data
          if (this.response.report.list.length > 0) {
            for (let v in this.response.report.chart.data) {
              this.response.report.chart.data[v] = this.response.report.chart.data[v]
            }
            let data = JSON.parse(JSON.stringify(this.configChartData))
            data.type = 'stackedcolumn2dlinedy'
            data.dataSource.categories[0].category = this.response.report.label
            let label = ['광고 수익', 'Active view']
            data.dataSource.chart.yaxisname = '광고 수익 (원)'
            data.dataSource.chart.syaxisname = label[1]
            data.dataSource.dataset.push(JSON.parse(JSON.stringify(this.configChartSet)))
            data.dataSource.dataset[0].seriesname = label[0]
            data.dataSource.dataset[0].data = this.response.report.chart.data[1]

            data.dataSource.dataset.push(JSON.parse(JSON.stringify(this.configChartAdd)))
            data.dataSource.dataset[1].seriesname = label[1]
            data.dataSource.dataset[1].data = this.response.report.chart.data[0]

            data.dataSource.dataset.push(JSON.parse(JSON.stringify(this.configChartAdd)))
            data.dataSource.dataset[2].seriesname = 'Contents view'
            data.dataSource.dataset[2].data = this.response.report.chart.data[2]

            this.chartData = data
          }
        }
      }).catch((error) => {
        if (error.message === 'Request failed with status code 401') {
          this.$router.push({ 'name': 'UserLogin' })
        } else {
          this.snack('warning', '관리자에게 문의 바랍니다.')
        }
      })
    },
    /* 매체-인벤토리 리스트 가져오기 */
    mediaInventoryList () {
      let send = {
        page: 1,
      }
      this.xhttp({
        url: 'me/inventory',
        method: 'get',
        params: send
      }).then((response) => {
        if (response.status === 200) {
          this.response.inventories = response.data.data.media
          this.config.inventoryOptions = this.response.inventories.map(function ($value) {
            return { value: $value.id, text: $value.name }
          })
          this.config.inventoryOptions.unshift({ value: '', text: '전체' })

          if (this.$route.query.inventory) {
            this.vModel.inventory = Number(this.$route.query.inventory)
          }
        }
      }).catch((error) => {
        if (error.message === 'Request failed with status code 401') {
          this.$router.push({ 'name': 'UserLogin' })
        } else {
          this.snack('warning', '관리자에게 문의 바랍니다.')
        }
      })
    },
    inventoryChange () {
      let send = {
        start: this.datePicker.start.date,
        end: this.datePicker.end.date,
      }

      if (this.vModel.inventory) {
        send.inventory = this.vModel.inventory
      }

      this.$router.push({ 'name': 'reportAds', query: send })
      this.refresh()
    }
  },
  computed: {
    resultReport () {
      if (this.response.report) {
        return this.response.report
      } else {
        return ''
      }
    },
  },
  watch: {
    datePicker: {
      handler (val) {
        if (val.start.date && val.end.date) {
          this.vModel.valid = true
        }
      },
      deep: true
    }
  }
}
</script>
