export const chart = {
  data () {
    return {
      configChartData: {
        type: '',
        width: '100%',
        height: '400',
        dataFormat: 'json',
        dataSource: {
          chart: {
            caption: '',
            subcaption: '',
            showhovereffect: '1',
            setadaptiveymin: '1',
            formatnumberscale: '0',
            drawcrossline: '1',
            yaxisname: '',
            plottooltext: '$seriesName : $dataValue',
            theme: 'fusion',
            plotHighlightEffect: 'fadeout'
          },
          categories: [
            {
              category: []
            }
          ],
          dataset: []
        }
      },
      configChartSet: { seriesname: '', data: [] },
      configChartAdd: {
        seriesname: '',
        parentyaxis: 'S',
        plottooltext: '$seriesName : $dataValue',
        renderas: 'Line',
        data: []
      }
    }
  },
}
