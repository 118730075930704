<!-- admin 정산보고서 일별 -->
<template>
  <v-container
          id='reportAds'
          fluid
          tag='section'>
    <v-row
            align='center'
            justify='center'>
      <v-col cols='12'>
        <base-material-card
                color='green'>
          <template v-slot:heading>
            <h1 class='display-2 font-weight-regular'>
              [ {{ datePicker.start.date }} - {{ datePicker.end.date }} ] 정산 보고서
            </h1>
          </template>
          <v-card-text>
            <v-row justify="space-between" align="center">
              <h2 style="padding: 20px 10px;">
                매출 현황 및 추이
              </h2>
              <v-row justify="end" align="center">
                <v-col cols="2">
                  <v-select
                          :items="config.mediaOptions"
                          v-model="vModel.media"
                          label="매체를 선택하세요."
                          color="secondary"
                          @change="partnerChange()" />
                </v-col>
                <v-col cols="2" v-if=" !vModel.partner ">
                  <v-select
                    :items="config.inventoryOptions"
                    v-model="vModel.inventory"
                    label="인벤토리를 선택하세요."
                    color="secondary"
                    @change="partnerChange()" />
                </v-col>
                <v-col cols="2">
                  <v-select
                          :items="config.partnersOptions"
                          v-model="vModel.partner"
                          label="CP를 선택하세요."
                          color="secondary"
                          @change="partnerChange()" />
                </v-col>
                <v-col cols="2">
                  <v-menu
                          ref="menu1"
                          v-model="datePicker.start.menu"
                          :close-on-content-click="false"
                          :return-value.sync="datePicker.start.date"
                          transition="scale-transition"
                          min-width="290px"
                          offset-y >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                              v-model="datePicker.start.date"
                              color="secondary"
                              label="Start date"
                              prepend-icon="mdi-calendar-outline"
                              readonly
                              v-on="on" />
                    </template>

                    <v-date-picker
                            v-model="datePicker.start.date"
                            color="secondary"
                            landscape
                            scrollable >
                      <v-spacer />
                      <v-btn
                              color="secondary"
                              @click="datePicker.start.menu = false" >
                        Cancel
                      </v-btn>

                      <v-btn
                              color="secondary"
                              @click="$refs.menu1.save(datePicker.start.date)" >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col>
                <span>-</span>
                <v-col cols="2">
                  <v-menu
                          ref="menu2"
                          v-model="datePicker.end.menu"
                          :close-on-content-click="false"
                          :return-value.sync="datePicker.end.date"
                          transition="scale-transition"
                          min-width="290px"
                          offset-y >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                              v-model="datePicker.end.date"
                              color="secondary"
                              label="End date"
                              prepend-icon="mdi-calendar-outline"
                              readonly
                              v-on="on" />
                    </template>

                    <v-date-picker
                            v-model="datePicker.end.date"
                            color="secondary"
                            landscape
                            scrollable >
                      <v-spacer />
                      <v-btn
                              color="secondary"
                              @click="datePicker.end.menu = false" >
                        Cancel
                      </v-btn>

                      <v-btn
                              color="secondary"
                              @click="$refs.menu2.save(datePicker.end.date)" >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col>
                <v-btn
                        :disabled="!vModel.valid"
                        color="success"
                        default
                        @click="submitDate">
                  검색
                </v-btn>
              </v-row>
            </v-row>
            <template v-if="resultReport">
              <v-row>
                <v-col cols="12" ref="chart1">
                  <fusioncharts
                          :type='chartData.type'
                          :width='chartData.width'
                          :height='chartData.height'
                          :dataFormat='chartData.dataFormat'
                          :dataSource='chartData.dataSource'
                  ></fusioncharts>
                </v-col>
              </v-row>
              <v-row>
                <v-col v-if=" !vModel.partner ">
                  <v-card outlined class="pa-3">
                    <v-card-text class="justify-center font-weight-regular text-center">
                      <h3>Active view</h3><br>
                      <h1>{{numReplace(resultReport.card.total_active_views)}}</h1>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col>
                  <v-card outlined class="pa-3">
                    <v-card-text class="justify-center font-weight-regular text-center">
                      <h3>Contents view</h3><br>
                      <h1>{{numReplace(resultReport.card.total_views)}}</h1>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col v-if=" !vModel.partner ">
                  <v-card outlined class="pa-3">
                    <v-card-text class="justify-center font-weight-regular text-center">
                      <h3>Rate</h3><br>
                      <h1>{{ getFeedContentRate(resultReport.card.total_active_views, resultReport.card.total_views) }}</h1>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col>
                  <v-card outlined class="pa-3">
                    <v-card-text class="justify-center font-weight-regular text-center">
                      <h3>총 매출</h3><br>
                      <h1 class="d-inline-block">{{numReplace(totalReplace(resultReport.card.total_revenue, resultReport.card.total_media_revenue, resultReport.card.total_content_revenue ))}}원</h1>
                      <span class="d-inline-block ml-1">(VAT제외)</span>
                      <div class="mt-4">
                        단가: <strong>{{ (totalReplace(resultReport.card.total_revenue, resultReport.card.total_media_revenue, resultReport.card.total_content_revenue) / resultReport.card.total_active_views).toFixed((2)) }}원</strong>
                      </div>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col>
                  <v-card outlined class="pa-3">
                    <v-card-text class="justify-center font-weight-regular text-center">
                      <h3>자사 수익</h3><br>
                      <h1 class="d-inline-block">{{numReplace(resultReport.card.total_revenue)}}원</h1>
                      <h3 class="d-inline-block ml-1">({{percentReplace(resultReport.card.total_revenue, totalReplace(resultReport.card.total_revenue, resultReport.card.total_media_revenue, resultReport.card.total_content_revenue))}}%)</h3>
                    </v-card-text>
                  </v-card>
                    <p style="font-size: 13px;margin-top:-8px;margin-left:5px;">* 아인애드에서 정산되는 수익과 같습니다.</p>
                </v-col>
                <v-col>
                  <v-card outlined class="pa-3">
                    <v-card-text class="justify-center font-weight-regular text-center">
                      <h3>매체 수익</h3><br>
                      <h1 class="d-inline-block">{{numReplace(resultReport.card.total_media_revenue)}}원</h1>
                      <h3 class="d-inline-block ml-1">({{percentReplace(resultReport.card.total_media_revenue, totalReplace(resultReport.card.total_revenue, resultReport.card.total_media_revenue, resultReport.card.total_content_revenue))}}%)</h3>
                      <div class="mt-4">
                        단가: <strong>{{ (resultReport.card.total_media_revenue / resultReport.card.total_active_views).toFixed((2)) }}원</strong>
                      </div>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col>
                  <v-card outlined class="pa-3">
                    <v-card-text class="justify-center font-weight-regular text-center">
                      <h3>CP 수익</h3><br>
                      <h1 class="d-inline-block">{{numReplace(resultReport.card.total_content_revenue)}}원</h1>
                      <h3 class="d-inline-block ml-1">({{percentReplace(resultReport.card.total_content_revenue, totalReplace(resultReport.card.total_revenue, resultReport.card.total_media_revenue, resultReport.card.total_content_revenue))}}%)</h3>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
              <v-divider class="mt-5 mb-5" />
              <v-row justify="space-between" align="center" class="ma-0">
                <h2 style="padding: 20px 10px;">
                  일별 정산 리포트
                </h2>
              </v-row>
              <v-col v-if="resultReport" cols="12" class="pa-0 ma-0">
                <ul>
                  <li><strong>XXX 단가</strong> = XXX / ActiveView (단위: 원)</li>
                </ul>
                <v-data-table
                        :headers=" vModel.partner ? config.headersReportPartner : config.headersReport "
                        :items="resultReport.list"
                        :items-per-page="resultReport.list.length"
                        item-key="key"
                        no-data-text="리스트가 없습니다."
                        @click:row="tableRowClick"
                        hide-default-footer>
                  <template v-slot:item="{ item }">
                    <tr @click="tableRowClick(item)">
                      <td class="text-center">{{item.daily}}</td>
                      <td class="text-center" v-if=" !vModel.partner ">{{ numReplace(item.page_views) }}</td>
                      <td class="text-center" v-if=" !vModel.partner ">{{ numReplace(item.active_views) }}</td>
                      <td class="text-center" v-if=" !vModel.partner">{{ getActiveViewsRate(item).toFixed(2) }}%</td>
                      <td class="text-center">{{ numReplace(item.views) }}</td>
                      <td class="text-center" v-if=" !vModel.partner ">{{ getFeedContentRate(item.active_views, item.views) }}</td>
                      <td class="text-center">{{ numReplace(totalReplace(item.revenue, item.media_revenue, item.content_revenue)) }}</td>
                      <td class="text-center">{{ numReplace(item.revenue) }}</td>
                      <td class="text-center">{{ numReplace(item.media_revenue) }}</td>
                      <td class="text-center">{{ numReplace(item.content_revenue) }}</td>
                      <td class="text-center" v-if=" !vModel.partner ">{{ toFixed(totalReplace(item.revenue, item.media_revenue, item.content_revenue), item.active_views) }}</td>
                      <td class="text-center" v-if=" !vModel.partner ">{{ toFixed(item.media_revenue, item.active_views) }}</td>
                    </tr>
                  </template>
                </v-data-table>
              </v-col>
            </template>
          </v-card-text>
        </base-material-card>
      </v-col>
    </v-row>
    <base-material-snackbar
            v-model="vModel.snackbar"
            :type="vModel.snackbarType"
            v-bind="{
          'top': true,
          'right': true
        }">
      <span style="display:block;width:300px;margin:0;">{{vModel.errorMessage}}</span>
    </base-material-snackbar>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'
import { common } from '../../mixins/common.js'
import { chart } from '../../mixins/chart.js'

export default {
  mixins: [ common, chart ],
  created () {
    this.refresh()
  },
  data () {
    return {
      response: {
        partner: null,
        media: null,
        inventory: null,
        report: null,
      },
      vModel: {
        valid: false,
        snackbar: false,
        snackbarType: 'warning',
        errorMessage: null,
        partner: '',
        media: '',
        mediaIdSlug: null,
        inventory: null,
      },
      datePicker: {
        start: {
          date: '',
          menu: false
        },
        end: {
          date: '',
          menu: false
        }
      },
      config: {
        headersReport: [
          { text: '날짜', value: 'daily', align: 'center' },
          { text: 'Page views', value: 'page_views', align: 'right' },
          { text: 'Active view', value: 'active_views', align: 'center' },
          { text: 'Active view(%)', value: 'active_views', align: 'center' },
          { text: 'Contents view', value: 'views', align: 'center' },
          { text: 'Rate', value: 'views', align: 'center' },
          { text: '총 매출', value: 'total', align: 'center' },
          { text: '자사 수익', value: 'revenue', align: 'center' },
          { text: '매체 수익', value: 'media_revenue', align: 'center' },
          { text: 'CP 수익', value: 'content_revenue', align: 'center' },
          { text: '총 매출 단가', value: 'total_unit', align: 'center' },
          { text: '매체 수익 단가', value: 'media_revenue_unit', align: 'center' },
        ],
        headersReportPartner: [
          { text: '날짜', value: 'daily', align: 'center' },
          { text: 'Contents view', value: 'views', align: 'center' },
          { text: '총 매출', value: 'total', align: 'center' },
          { text: '자사 수익', value: 'revenue', align: 'center' },
          { text: '매체 수익', value: 'media_revenue', align: 'center' },
          { text: 'CP 수익', value: 'content_revenue', align: 'center' },
        ],
        partnersOptions: [],
        mediaOptions: [],
        inventoryOptions: [],
      },
      chartData: {}
    }
  },
  methods: {
    ...mapActions(['xhttp']),
    snack (type, val) {
      this.vModel.errorMessage = val
      this.vModel.snackbarType = type
      this.vModel.snackbar = true
    },
    /* cp 선택시 */
    partnerChange () {
      let send = {}
      if (this.$route.query.start) {
        this.datePicker.start.date = this.$route.query.start
        this.datePicker.end.date = this.$route.query.end
        send.start = this.$route.query.start
        send.end = this.$route.query.end
      }

      if (this.vModel.media) {
        send.media = this.vModel.media
      }

      if (this.vModel.inventory) {
        send.inventory = this.vModel.inventory
      }

      if (this.vModel.partner) {
        send.partner = this.vModel.partner

        if (this.vModel.inventory) {
          delete send.inventory
          this.config.inventoryOptions = []
        }
      }
      this.$router.push({ 'name': 'reportAds', query: send })
      this.refresh()
    },
    /* 날짜 검색 버튼 클릭시 */
    submitDate (val) {
      let start = this.datePicker.start.date
      let end = this.datePicker.end.date
      let startNum = Number(start.replace(/-/gi, ''))
      let endNum = Number(end.replace(/-/gi, ''))
      if (startNum > endNum) {
        this.snack('warning', '시작일을 종료일보다 앞날짜로 선택하세요.')
      } else {
        let send = {
          start: start,
          end: end
        }
        sessionStorage.setItem('cdpDate', JSON.stringify(send))
        if (this.$route.query.media) {
          send.media = this.$route.query.media
        }

        if (this.$route.query.inventory) {
          send.inventory = this.$route.query.inventory
        }

        if (this.$route.query.partner) {
          send.partner = this.$route.query.partner
        }
        this.$router.push({ 'name': 'reportAds', query: send })
        this.refresh()
      }
    },
    /* 테이블 리스트 클릭 */
    tableRowClick (v) {
      let send = {}
      if (this.$route.query.media) {
        send.media = this.$route.query.media
      } else {
        // 매체가 선택되지 않은 경우 이벤트 무시
        return
      }

      if (this.$route.query.inventory) {
        send.inventory = this.$route.query.inventory
      }

      if (this.$route.query.partner) {
        send.partner = this.$route.query.partner
      }
      this.$router.push({ name: 'reportAdsHourly', params: { date: v.daily }, query: send })
    },
    /* 페이지 로드시 */
    refresh () {
      if (this.$route.query.start) {
        this.datePicker.start.date = this.$route.query.start
        this.datePicker.end.date = this.$route.query.end
      } else if (sessionStorage.getItem('cdpDate')) {
        let date = JSON.parse(sessionStorage.getItem('cdpDate'))
        this.datePicker.start.date = date.start
        this.datePicker.end.date = date.end
      } else {
        this.datePicker.start.date = this.$moment(new Date()).add(-6, 'days').format('YYYY-MM-DD')
        this.datePicker.end.date = this.$moment(new Date()).format('YYYY-MM-DD')
      }
      let send = {
        sdate: this.datePicker.start.date,
        edate: this.datePicker.end.date
      }
      this.vModel.media = ''
      if (this.$route.query.media) {
        send.media = this.$route.query.media
        this.vModel.media = Number(this.$route.query.media)
      }

      this.vModel.inventory = ''
      if (this.$route.query.inventory) {
        send.inventory = this.$route.query.inventory
        this.vModel.inventory = Number(this.$route.query.inventory)
      }

      this.vModel.partner = ''
      if (this.$route.query.partner) {
        send.partner = this.$route.query.partner
        this.vModel.partner = Number(this.$route.query.partner)
      }
      this.response.report = null
      this.chartData = []
      this.getReport(send)
      this.getPartners()
      this.mediaList()
    },
    /* cp 리스트 가져오기 */
    getPartners () {
      let send = {
        per: 999,
        page: 1,
        orderby: 'name',
        direction: 'asc',
        contract_status_id: 'for_report',
        resolution: 'lo',
      }
      this.xhttp({
        url: '/partners',
        method: 'get',
        params: send
      }).then((response) => {
        if (response.status === 200) {
          this.response.partner = response.data.data.partners
          this.config.partnersOptions = this.response.partner.map(function ($value) {
            return { value: $value.id, text: $value.name }
          })
          this.config.partnersOptions.unshift({ value: '', text: '전체' })
        }
      }).catch((error) => {
        if (error.message === 'Request failed with status code 401') {
          this.$router.push({ 'name': 'UserLogin' })
        } else {
          this.snack('warning', '관리자에게 문의 바랍니다.')
        }
      })
    },
    /* 매체 리스트 가져오기 */
    mediaList () {
      let send = {
        per: 999,
        page: 1,
        orderby: 'kor_name',
        direction: 'asc',
        contract_status_id: 'for_report',
        resolution: 'lo',
      }
      this.xhttp({
        url: '/media',
        method: 'get',
        params: send
      }).then((response) => {
        if (response.status === 200) {
          this.response.media = response.data.data.media
          this.config.mediaOptions = this.response.media.map(function ($value) {
            return { value: $value.id, text: $value.kor_name, key: $value.key }
          })
          this.config.mediaOptions.unshift({ value: '', text: '전체', key: null })

          if (this.vModel.media && !this.vModel.partner) {
            let tmp = this.config.mediaOptions.filter(option => option.value === this.vModel.media)
            let option = tmp ? tmp[0] : null
            if (option) {
              this.vModel.mediaIdSlug = option.key
              this.mediaInventoryList()
            }
          }
        }
      }).catch((error) => {
        if (error.message === 'Request failed with status code 401') {
          this.$router.push({ 'name': 'UserLogin' })
        } else {
          this.snack('warning', '관리자에게 문의 바랍니다.')
        }
      })
    },
    /* 매체-인벤토리 리스트 가져오기 */
    mediaInventoryList () {
      let send = {
        page: 1,
        resolution: 'lo',
      }
      this.xhttp({
        url: this.vModel.mediaIdSlug + '/inventory',
        method: 'get',
        params: send
      }).then((response) => {
        if (response.status === 200) {
          this.response.inventories = response.data.data.media
          this.config.inventoryOptions = this.response.inventories.map(function ($value) {
            return { value: $value.id, text: $value.name }
          })
          this.config.inventoryOptions.unshift({ value: '', text: '전체' })
        }
      }).catch((error) => {
        if (error.message === 'Request failed with status code 401') {
          this.$router.push({ 'name': 'UserLogin' })
        } else {
          this.snack('warning', '관리자에게 문의 바랍니다.')
        }
      })
    },
    /* 차트 정보 가져오기 */
    getReport (send) {
      this.xhttp({
        url: '/reports/ads/daily/admin',
        method: 'get',
        params: send
      }).then((response) => {
        if (response.status === 200) {
          this.response.report = response.data.data
          if (this.response.report.list.length > 0) {
            for (let v in this.response.report.chart.data) {
              this.response.report.chart.data[v] = this.response.report.chart.data[v]
            }
            let data = JSON.parse(JSON.stringify(this.configChartData))
            data.type = 'stackedcolumn2dlinedy'
            data.dataSource.categories[0].category = this.response.report.label
            let label = ['자사 수익', '매체 수익', 'CP 수익']
            data.dataSource.chart.yaxisname = '수익 (원)'
            data.dataSource.chart.syaxisname = 'Contents view'
            for (let i = 0; i < 3; i++) {
              data.dataSource.dataset.push(JSON.parse(JSON.stringify(this.configChartSet)))
              data.dataSource.dataset[i].seriesname = label[i]
              data.dataSource.dataset[i].data = this.response.report.chart.data[i + 1]
            }
            data.dataSource.dataset.push(JSON.parse(JSON.stringify(this.configChartAdd)))
            data.dataSource.dataset[3].seriesname = 'Active view'
            data.dataSource.dataset[3].data = this.response.report.chart.data[4]
            data.dataSource.dataset.push(JSON.parse(JSON.stringify(this.configChartAdd)))
            data.dataSource.dataset[4].seriesname = 'Contents view'
            data.dataSource.dataset[4].data = this.response.report.chart.data[0]
            this.chartData = data
          }
        }
      }).catch((error) => {
        if (error.message === 'Request failed with status code 401') {
          this.$router.push({ 'name': 'UserLogin' })
        } else {
          this.snack('warning', '관리자에게 문의 바랍니다.')
        }
      })
    },

    /**
     * FEED/CONTENT Rate 계산 후 반환
     * @param feedImpressions
     * @param contentViewableImpressions
     * @param precision
     * @returns {string}
     */
    getFeedContentRate (feedImpressions, contentViewableImpressions, precision = 2) {
      let value = (feedImpressions === 0 || contentViewableImpressions === 0) ? 0.0 : 100 / feedImpressions * contentViewableImpressions
      return value.toFixed(precision)
    },
    getActiveViewsRate (item) {
      if (item.page_views === 0) {
        return 0
      }

      return item.active_views / item.page_views * 100
    }
  },
  computed: {
    resultReport () {
      if (this.response.report) {
        return this.response.report
      } else {
        return ''
      }
    },
  },
  watch: {
    datePicker: {
      handler (val) {
        if (val.start.date && val.end.date) {
          this.vModel.valid = true
        }
      },
      deep: true
    }
  }
}
</script>
